<template>
  <div>
    <Pane />
    <a-card class="container">
      <a-form-model layout="inline" @keyup.enter.native="query">
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-range-picker v-model="form.date" />
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-col>
          <a-col :span="2">
            <div class="right" style="margin-top: 4px">
              <a-button-group>
                <a-tooltip>
                  <template slot="title"> 导出 </template>
                  <a-button @click="download" icon="file-excel"> </a-button>
                </a-tooltip>
              </a-button-group>
            </div>
          </a-col>
        </a-row>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :columns="[
          {
            title: '领用人员',
            dataIndex: 'applicantName',
            align: 'center',
          },
          {
            title: '领用时间',
            dataIndex: 'createAt',
            align: 'center',
          },
          ...titleList,
        ]"
        :dataSource="list"
        :loading="loading"
        :pagination="false"
      >
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchApplyDeptList } from "@/api/oa/material";
import { downloadByHtml } from "@/utils/xlsx";
import moment from "moment";
export default {
  name: "materialDetail",
  mixins: [watermark],

  data() {
    return {
      deptId: "",
      form: {},
      loading: false,
      titleList: [],
      list: [],
    };
  },

  activated() {
    const { query } = this.$route;
    const { deptId, startTime, endTime } = query || {};
    this.deptId = deptId;

    if (startTime && endTime) {
      this.form = {
        date: [moment(startTime), moment(endTime)],
      };
    } else {
      // 本月第一天和本月最后一天
      const year = moment().year();
      const month = moment().month();

      this.form = {
        date: [
          moment([year, month, 1]),
          moment([year, month + 1, 1]).subtract(1, "days"),
        ],
      };
    }

    this.getList();
  },

  methods: {
    getList() {
      const { deptId } = this;
      const { date } = this.form;

      let startTime, endTime;
      if (Array.isArray(date) && date.length === 2) {
        startTime = date[0].format("YYYY-MM-DD");
        endTime = date[1].format("YYYY-MM-DD");
      }

      this.loading = true;
      fetchApplyDeptList({
        startTime,
        endTime,
        deptId,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.titleList) && Array.isArray(res.dataList)) {
            this.titleList = res.titleList.map((item) => ({
              title: item,
              children: [
                {
                  title: "数量",
                  dataIndex: item + "数量",
                  align: "center",
                },
                {
                  title: "金额(元)",
                  dataIndex: item + "金额",
                  align: "center",
                },
              ],
            }));

            this.list = res.dataList
              .filter((item) => Array.isArray(item.dataList))
              .map((item) => {
                const obj = {};
                for (let i = 0; i < res.titleList.length; i++) {
                  const title = res.titleList[i];
                  const values = item.dataList[i];
                  obj[title + "数量"] = values.quantity;
                  obj[title + "金额"] = values.totalPrice;
                }

                return {
                  applicantName: item.applicantName,
                  createAt:
                    typeof item.createAt === "string"
                      ? item.createAt.split(" ")[0]
                      : "",
                  ...obj,
                };
              });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    query() {
      this.getList();
    },
    reset() {
      this.form = {};
      this.getList();
    },

    download() {
      downloadByHtml(document.getElementsByTagName("table")[0], "物料申领台账");
    },
  },
};
</script>

